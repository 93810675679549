<template>
  <div class="fullHeight">
    <v-card class="mt-1 pa-8 pt-0 heightFull">
      <v-card-title class="mb-4" v-if="templates && templates.length">
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          @keyup.enter="getList"
          append-icon="mdi-magnify"
          :placeholder="$t('search')"
          single-line
          hide-details
        ></v-text-field>
        <v-btn @click="listBoolens" class="my-2 mr-2 ml-4" outlined color="primary">
          <v-icon class="mr-2">mdi-view-grid-outline</v-icon>
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="mx-0" v-if="splite">
        <v-col
          class="pa-0 splite"
          md="3"
          sm="6"
          xs="12"
          v-for="(template, i) in templates"
          :key="i"
          :search="search"
          v-if="$store.getters.checkPermission(template.visible)"
        >
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :to="template.id == 134 ? '/salary-cert' : (template.id == 15700 || template.id == 15800 ? '/firm-blank/' + template.id : '../create/' + template.id)"
                :class="`elevation-${hover ? 16 : 1}`"
                :title="template['name_' + $i18n.locale]"
                class="ma-2 pa-3 border_left"
              >
                <v-card-title class="text_nowrap pa-0">
                  {{
                  template["name_" + $i18n.locale]
                  }}
                </v-card-title>

                <v-card-subtitle class="pa-0 department_text mr-7">
                  {{
                  template.department["name_" + $i18n.locale]
                  }}
                </v-card-subtitle>

                <v-btn
                  dark
                  small
                  fab
                  color="teal"
                  class="mr-1 mb-1"
                  style="position: absolute; right: 0; bottom: 0;"
                >
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>

      <v-row class="mx-0" v-if="lists">
        <v-col class="pa-0" xs="12">
          <v-data-table
            dense
            hide-default-footer
            :headers="headers"
            :items="templates"
            :footer-props="{
              itemsPerPageOptions: [-1],
              itemsPerPageAllText: $t('itemsPerPageAllText'),
              itemsPerPageText: $t('itemsPerPageText'),
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right'
            }"
          >
            <!-- <template
              v-slot:item.id="{ item }"
            >{{templates.map(function(x) {return x.id; }).indexOf(item.id) + 1}}</template>
            <template v-slot:item.text="{ item }">{{ item['name_'+$i18n.locale] }}</template>
            <template v-slot:item.department="{ item }">{{ item.department['name_'+$i18n.locale] }}</template>-->

            <template v-slot:item="{ item, index }">
              <tr
                v-if="$store.getters.checkPermission(item.visible)"
                @click="$router.push('../create/' + item.id)"
                style="cursor: pointer"
              >
                <td style="max-width: 30px;">{{ index + 1 }}</td>
                <td style="max-width: 300px;">{{ item["name_" + $i18n.locale] }}</td>
                <td style="max-width: 300px;">{{ item.department["name_" + $i18n.locale] }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-col class="pa-0 mt-8" sm="12" v-if="!loading && templates.length == 0">
        <v-alert text outlined color="deep-orange" type="error">
          {{
          $t("noDataText")
          }}
        </v-alert>
      </v-col>
    </v-card>

    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios").default;
export default {
  data() {
    return {
      templates: [],
      search: "",
      splite: true,
      lists: false,
      loading: false,
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 175;
    },
    headers() {
      return [
        {
          text: "#",
          align: "center",
          sortable: false,
          width: 30,
          value: "id",
        },
        { text: this.$t("document.documentTypes"), value: "text", width: 200 },
        {
          text: this.$t("department.index"),
          value: "department",
          width: 200,
        },
      ];
    },
  },

  watch: {
    $route(to, from) {
      this.getList();
    },
  },
  methods: {
    getList() {
      this.loading = true;
      axios
        .post(
          this.$store.state.backend_url + "api/document-templates/get-name",
          {
            document_type_id: this.$route.params.documentTypeId,
            language: this.$i18n.locale,
            search: this.search,
          }
        )
        .then((res) => {
          this.templates = res.data;
          this.templates.map((v) => {
            v.visible =
              v.name_uz_latin
                .toLowerCase()
                .trim()
                .replace(/ /g, "_")
                .replace(/'/g, "")
                .replace(/,/g, "")
                .replace("?", "")
                .replace("(", "")
                .replace(/`/g, "")
                .replace(/\\/g, "")
                .replace("/", "")
                .replace(/«/g, "")
                .replace(/»/g, "")
                .replace(/!/g, "")
                .replace(")", "") + "-create";
          });
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    listBoolens() {
      this.splite = !this.splite;
      this.lists = !this.lists;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style scoped>
.fullHeight {
  height: calc(100% - 0px);
}
.heightFull {
  height: 100%;
}
.text_nowrap {
  white-space: normal;
  display: block;
  /* display: -webkit-box; */
  /* max-width: 50px; */
  height: 40px;
  margin: 0 auto;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.department_text {
  white-space: normal;
  display: block;
  display: -webkit-box;
  /* max-width: 50px; */
  height: 40px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.3;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-shadow: 1px 1px 5px #000; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}
.splite .border_left {
  /* border: 0.5px solid #9e9e9e; */
  border-left: 3px solid teal;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.splite .elevation-1 {
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 1px rgba(0, 0, 0, 0.12) !important;
}
.right_position {
  display: flex;
  justify-content: flex-end;
}
</style>
